import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

interface QrCodeProps {
  outerColor?: string;
  innerColor?: string;
}

export default function QrCode({ outerColor = '#000000ff', innerColor = '#ffffffff' }: QrCodeProps) {
  const [qrcodeHtml, setQrcodeHtml] = useState('');
  const { protocol, host } = window.location;

  useEffect(() => {
    QRCode.toString(
      `${protocol}//${host}/app`,
      {
        margin: 1,
        color: {
          dark: outerColor,
          light: innerColor,
        },
      },
      function (err: Error, svg: string) {
        if (err) throw err;
        setQrcodeHtml(svg);
      },
    );
  }, [protocol, host, outerColor, innerColor]);

  return <div style={{ width: 90 }} dangerouslySetInnerHTML={{ __html: qrcodeHtml }} />;
}
