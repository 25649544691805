export default function validateNumber(num: number) {
  if (Number.isNaN(num)) {
    console.error('Attempted to round a non-number');
  }

  if (num === Infinity || num === -Infinity) {
    console.error('Attempted to round positive or negative infinity');
  }

  return num;
}
