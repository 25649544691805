import React from 'react';
import { AccountDocData } from '../types';

interface AccountMaskProps {
  account: AccountDocData;
}

export default function AccountMask({ account }: AccountMaskProps) {
  return (
    <span className="text-nowrap" data-account-id={account.accountId}>
      {account.institution.name}
      {account.mask && <> x{account.mask}</>}
    </span>
  );
}
