import React from 'react';
import { utcDay } from '../shared/days';

/**
 * Renders the date of the transaction in the format "Mon, Feb 23"
 * Adds the year only if the transaction date is last year for brevity.
 */
export default function TransactionDate({ date }) {
  const day = utcDay(date);
  const startOfYear = utcDay().startOf('year');

  return (
    <span className="text-nowrap" title={day.toString()}>
      {day.format('ddd, MMM D')}
      {day.isBefore(startOfYear) && <span>, {day.year()}</span>}
    </span>
  );
}
