import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Dashboard from '../components/Dashboard/Dashboard';
import { Navigate, useParams } from 'react-router-dom';
import { utcDay } from '../shared/days';
import BudgetCalendar, { Timescale } from '../shared/BudgetCalendar';
import { Metric, useTrackMetric } from '../utils/metrics';

function DashboardPage() {
  useDocumentTitle('Dashboard');
  useTrackMetric(Metric.DASHBOARD_PAGE_VISITED);

  const { date: dateString, timescale: timescaleString } = useParams();

  // Parameter validation: redirect back to the default view (the current week) if the parameters are invalid
  if (dateString && !dateString.match(/^\d{4}-\d{2}-\d{2}$/)) return <Navigate to="/" replace />;
  if (timescaleString && timescaleString !== Timescale.Month && timescaleString !== Timescale.Week) return <Navigate to="/" replace />;

  const timescale = timescaleString && timescaleString === Timescale.Month ? Timescale.Month : Timescale.Week;
  const budgetPeriods = BudgetCalendar.getBudgetPeriodsByDate(timescale, dateString ? utcDay(dateString) : utcDay());

  return <Dashboard budgetPeriods={budgetPeriods} timescale={timescale} />;
}

export default DashboardPage;
