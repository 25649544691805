import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { BsDatabaseLock, BsSafe2, BsShieldLock } from 'react-icons/bs';
import { useAuth } from '../../App';
import ConfettiExplosion from 'react-confetti-explosion';
import dayjs from 'dayjs';

export default function PreambleStep() {
  const { userData } = useAuth();

  let isShownConfetti = false;
  if (userData?.createdAt && userData.createdAt.toDate) {
    const createdAt = dayjs(userData.createdAt.toDate());
    isShownConfetti = createdAt.isAfter(dayjs().subtract(5, 'seconds'));
  }

  return (
    <>
      <div className="text-center">
        <div className="lead fw-bolder mb-2">Welcome to Spend!</div>
        {isShownConfetti && (
          <div style={{ margin: '0 auto', width: 0, height: 0 }}>
            <ConfettiExplosion duration={5000} particleCount={60} />
          </div>
        )}
        <p className="mb-0">
          Spend works by creating a secure, read-only connection to your bank. We pride ourselves on our security and privacy and use
          bank-grade protocols to protect your data.
        </p>
      </div>

      <ListGroup>
        <ListGroup.Item>
          <div className="text-muted d-flex align-items-center">
            <div style={{ height: 24, width: 24 }} className="me-3 text-center">
              <BsSafe2 size={24} className="me-3" style={{ verticalAlign: 'initial' }} />
            </div>
            <div className="small-font">Data is transferred using Transport Layer Security (TLS).</div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="text-muted d-flex align-items-center">
            <div style={{ height: 24, width: 24 }} className="me-3 text-center">
              <BsDatabaseLock size={24} className="me-3" style={{ verticalAlign: 'initial' }} />
            </div>
            <div className="small-font">Data is stored using 256-bit AES encryption.</div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div className="text-muted d-flex align-items-center">
            <div style={{ height: 24, width: 24 }} className="me-3 text-center">
              <BsShieldLock size={24} style={{ verticalAlign: 'initial' }} />
            </div>

            <div className="small-font">We promise never to give or sell your data to third parties.</div>
          </div>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
}
