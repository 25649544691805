import React from 'react';
import Accounts from '../components/Accounts';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { Metric, useTrackMetric } from '../utils/metrics';

function AccountsPage() {
  useDocumentTitle('Accounts');
  useTrackMetric(Metric.ACCOUNTS_PAGE_VISITED);

  return <Accounts />;
}

export default AccountsPage;
