import validateNumber from './validateNumber';

/**
 * Formats the runway number to have one decimal place.
 *
 * @param num - The runway number to be formatted.
 * @returns The formatted runway number.
 */
export default function formatRunway(num: number) {
  validateNumber(num);
  return Math.max(Math.round(num * 10) / 10, 0);
}
