import React from 'react';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import List from '../components/List';
import { BsPersonLock, BsSafe, BsShieldLock } from 'react-icons/bs';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { Metric, useTrackMetric } from '../utils/metrics';

function SecuritySummary() {
  const iconSize = '1.6em';

  return (
    <List style={{ gap: 24, marginBottom: '0.4em' }}>
      <List.Item icon={<BsShieldLock size={iconSize} />}>
        <div>
          <strong>Bank-grade standards</strong>
        </div>
        <div className="text-muted small-font">
          Data is transfered using Transport Layer Security (TLS) and stored at rest using 256-bit AES encryption.
        </div>
      </List.Item>
      <List.Item icon={<BsSafe size={iconSize} />}>
        <div>
          <strong>No access to your money</strong>
        </div>
        <div className="text-muted small-font">Spend never sees your bank credentials and cannot access or transfer your money.</div>
      </List.Item>
      <List.Item icon={<BsPersonLock size={iconSize} />}>
        <div>
          <strong>Your data is yours</strong>
        </div>
        <div className="text-muted small-font">Your data will never be sold and you can delete it at any time.</div>
      </List.Item>
    </List>
  );
}

export default function SecurityPage() {
  useDocumentTitle('Security');
  useTrackMetric(Metric.SECURITY_PAGE_VISITED);

  return (
    <div className="wrapper">
      <section className="floating-card">
        <Stack gap={3}>
          <h1 style={{ marginBottom: 0 }}>Your security comes first</h1>

          <SecuritySummary />

          <hr />

          <div>We take your security seriously at Spend.</div>
          <div>
            Spend does not have access to your bank credentials or your money. Our system uses data from financial institutions to predict
            your budgeting, but we cannot transfer money to or from your accounts.
          </div>
          <div>
            We never receive your login information and therefore cannot store it. We use{' '}
            <Link to="https://plaid.com/why-is-plaid-involved/" target="_blank">
              Plaid
            </Link>{' '}
            to integrate with your financial institution. Plaid powers over 7,000 apps and services and integrates with over 12,000
            financial institutions. In most cases, you’ll log in directly with your bank, which authorizes Plaid read-only access to your
            data data on Spend’s behalf. This authentication process results in an access token that Spend uses to keep your data
            up-to-date. Access tokens are redacted from our server logs and stored so securely that not even you have access to them.
          </div>
          <div>
            We take precautions to protect your information. When you submit sensitive information via the website, your information is
            protected during transfer and when stored in our database.
          </div>
          <div>
            All data is exclusively transfered with{' '}
            <Link to="https://en.wikipedia.org/wiki/Transport_Layer_Security" target="_blank">
              Transport Layer Security (TLS)
            </Link>
            . TLS is the cryptographic protocol that secures the{' '}
            <Link to="https://en.wikipedia.org/wiki/HTTPS" target="_blank">
              HTTPS
            </Link>{' '}
            connections we use, when transmitting any data.
          </div>
          <div>
            When data is stored, it is encryped at rest using 256-bit{' '}
            <Link to="https://en.wikipedia.org/wiki/Advanced_Encryption_Standard" target="_blank">
              Advanced Encryption Standard (AES)
            </Link>
            . This ensures that if our data is stolen, it cannot be read. Access to data is secured at the user level, ensuring that you,
            and only you, can read your data.
          </div>
          <div>
            While data is secured behind your personal login, in certain cases Spend employees may need the information to perform a
            specific job (for example, billing or customer service). Spend employees will never access your data without your verbal or
            written consent.
          </div>
          <div>
            We realize that by using Spend you are sharing{' '}
            <Link to="https://en.wikipedia.org/wiki/Personal_data" target="_blank">
              Personally Identifiable Information (PII)
            </Link>
            . We ensure the servers in which we store personally identifiable information are kept in a secure environment. We redact PII
            from our logging systems and periodically work with 3rd-party application and data security audit firms to ensure our procedures
            are current.
          </div>
        </Stack>
      </section>
    </div>
  );
}

export { SecuritySummary };
