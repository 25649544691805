import React from 'react';
import { Button, Image, Stack } from 'react-bootstrap';

interface ErrorPageProps {
  error: Error;
}

export default function ErrorPage({ error }: ErrorPageProps) {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Stack dir="vertical" gap={3} className="d-flex justify-content-center text-center vh-100">
        <Image
          src="/envelope-bug.svg"
          style={{
            height: '6em',
          }}
        />
        <h1>Error</h1>
        <p>The page you are trying to view has an error and cannot be shown.</p>

        <pre>
          <code>{error.stack}</code>
        </pre>

        <Stack gap={2} direction="horizontal" className="mx-auto">
          <Button variant="primary" onClick={() => window.location.reload()}>
            Refresh the page
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Clear local storage
          </Button>
          <Button
            variant="primary"
            href={`mailto:help@spend.money?subject=Error%20on%20${window.location.pathname}&body=${encodeURIComponent(error.stack)}`}
          >
            Email support
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
