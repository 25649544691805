import React from 'react';
import { Alert } from 'react-bootstrap';

interface ErrorAlertProps {
  error: Error;
  onClose?: () => void;
}

export default function ErrorAlert({ error, onClose }: ErrorAlertProps) {
  if (!error) return null;

  return (
    <Alert variant="danger" dismissible onClose={onClose}>
      {error.message}
    </Alert>
  );
}
