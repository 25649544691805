import React from 'react';

const cleanPercentage = (percentage) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ color, percentage }) => {
  const r = 6;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      r={r}
      cx={9}
      cy={9}
      fill="transparent"
      stroke={strokePct !== circ ? color : ''} // remove color as 0% sets full circumference
      strokeWidth={2}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    ></circle>
  );
};

const Pie = ({ percentage, color }) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={18} height={18}>
      <g transform={`rotate(-90 ${'9 9'})`}>
        <Circle color="var(--bs-light-border-subtle)" percentage={100} />
        <Circle color={color} percentage={pct} />
      </g>
    </svg>
  );
};

export default Pie;

// rgba(255, 255, 255, 0.2)
