import React, { useEffect, useState } from 'react';
import { deleteField, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Modal, Stack } from 'react-bootstrap';
import { useAuth } from '../../App';
import { Route } from '../../Routes';
import Loader from '../Loader';
import db from '../../firebase';
import ExpensesStep from './ExpensesStep';
import BudgetCompleteStep from './BudgetCompleteStep';
import { Metric, trackMetric } from '../../utils/metrics';
import BetterButton from '../BetterButton';
import { BsArrowLeft, BsArrowRight, BsLifePreserver } from 'react-icons/bs';
import useAccounts from '../../hooks/useAccounts';
import IncomeStep from './IncomeStep';
import PreambleStep from './PreambleStep';
import ConnectAccountsStep from './ConnectAccountsStep';
import useTransactions from '../../hooks/useTransactions';
import Tip from '../Tip';
import BudgetCalendar from '../../shared/BudgetCalendar';

export const STEPS = ['preamble', 'add-accounts', 'classify-incomes', 'classify-expenses', 'review-budget'];

export default function Onboarding() {
  const { uid, userData } = useAuth();
  const navigate = useNavigate();
  const [showIncomesWarning, setShowIncomesWarning] = useState(false);
  const [showRecurringIncomesWarning, setShowRecurringIncomesWarning] = useState(false);
  const [showExpensesWarning, setShowExpensesWarning] = useState(false);
  const { includedAccounts: accounts, includedCheckingAccounts, accountsById } = useAccounts();

  const step = userData?.onboardingStep;

  const {
    recurrence: { start: recurrencePeriodStart, end: recurrencePeriodEnd },
  } = new BudgetCalendar().getBudgetPeriodsByOffset();

  const { incomes, recurringIncomes } = useTransactions({
    startDate: recurrencePeriodStart,
    endDate: recurrencePeriodEnd,
    accounts: includedCheckingAccounts,
  });

  const { expenses, recurringExpenses } = useTransactions({
    startDate: recurrencePeriodStart,
    endDate: recurrencePeriodEnd,
    accounts,
  });

  useEffect(
    // Don't track the initial load when we don't have a step
    () => step && trackMetric(`${Metric.ONBOARDING_STEP_REACHED}_${step.toLowerCase().replace('-', '_')}`),
    [step],
  );

  useEffect(() => {
    showIncomesWarning && trackMetric(Metric.ONBOARDING_INCOMES_WARNING_SHOWN);
    showRecurringIncomesWarning && trackMetric(Metric.ONBOARDING_RECURRING_INCOMES_WARNING_SHOWN);
    showExpensesWarning && trackMetric(Metric.ONBOARDING_EXPENSES_WARNING_SHOWN);
  }, [showIncomesWarning, showRecurringIncomesWarning, showExpensesWarning]);

  const userDocRef = doc(db, 'users', uid);

  function handleNextStep(event, bypassWarning = false) {
    event.preventDefault();

    const nextStepIndex = stepIndex + 1;
    const nextStep = STEPS[nextStepIndex];

    if (nextStepIndex === 2 && incomes.length === 0) {
      setShowIncomesWarning(true);
      return;
    }

    if (!bypassWarning && nextStepIndex === 3 && recurringIncomes.length === 0) {
      setShowRecurringIncomesWarning(true);
      return;
    }

    if (!bypassWarning && nextStepIndex === 4 && recurringExpenses.length === 0) {
      setShowExpensesWarning(true);
      return;
    }

    setShowExpensesWarning(false);
    setShowRecurringIncomesWarning(false);

    if (stepIndex === STEPS.length - 1) {
      // Last step
      updateDoc(userDocRef, {
        onboardingStep: deleteField(),
        updatedAt: serverTimestamp(),
      }).then(() => {
        trackMetric(Metric.ONBOARDING_COMPLETED);
        navigate(Route.Root, { replace: true });
      });
    } else if (nextStep) {
      updateDoc(userDocRef, {
        onboardingStep: nextStep,
        updatedAt: serverTimestamp(),
      });
    }
  }

  function handlePrevStep() {
    if (step === STEPS[0]) return;

    const stepIndex = STEPS.indexOf(step);
    const prevStepIndex = stepIndex - 1;
    const prevStep = STEPS[prevStepIndex];

    updateDoc(userDocRef, {
      onboardingStep: prevStep,
      updatedAt: serverTimestamp(),
    });
  }

  let canPrev = true;
  let canNext = true;

  if (step === STEPS[0]) canPrev = false;
  if (step === STEPS[1]) canNext = includedCheckingAccounts.length > 0;

  const stepIndex = STEPS.indexOf(step);

  return (
    <Stack gap={4}>
      <Loader isLoading={!userData} />

      {canPrev && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <BetterButton variant="link" beforeIcon={<BsArrowLeft />} onClick={handlePrevStep} disabled={!canPrev}>
              Back
            </BetterButton>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}></div>
          <div>
            <BetterButton variant="link" onClick={handleNextStep} afterIcon={<BsArrowRight />} disabled={!canNext}>
              Next
            </BetterButton>
          </div>
        </div>
      )}

      {stepIndex >= 2 && userData?.demoData && (
        <Alert className="small-font mb-0 text-center">Please note that you’re setting up Spend with demo data.</Alert>
      )}

      {step === STEPS[0] && <PreambleStep />}

      {step === STEPS[1] && <ConnectAccountsStep />}

      {step === STEPS[2] && (
        <IncomeStep startDate={recurrencePeriodStart} endDate={recurrencePeriodEnd} accountsById={accountsById} incomes={incomes} />
      )}

      {step === STEPS[3] && (
        <ExpensesStep startDate={recurrencePeriodStart} endDate={recurrencePeriodEnd} accountsById={accountsById} expenses={expenses} />
      )}

      {step === STEPS[4] && <BudgetCompleteStep />}

      {canNext && (
        <div style={{ margin: '0 auto' }}>
          <BetterButton onClick={handleNextStep} afterIcon={<BsArrowRight />}>
            {step === STEPS[0] && 'Get started'}
            {step === STEPS[1] && 'I’m done adding accounts'}
            {step === STEPS[2] && 'Looks good!'}
            {step === STEPS[3] && 'All done!'}
            {step === STEPS[4] && 'Track my spending'}
          </BetterButton>
        </div>
      )}

      <Modal show={showIncomesWarning} onHide={() => setShowIncomesWarning(false)}>
        <Modal.Header closeButton>
          <Modal.Title>No checking account deposits?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We couldn’t find any deposits to your checking accounts for the last month. Ensure you’ve connected a checking account where
            you’ve received income in the last month and that it’s included in your budget. You may also consider waiting a few more moments
            for these transactions to download.
          </p>

          <Tip icon={<BsLifePreserver />}>
            If you double-checked everything and you’re still seeing this, there might be a glitch. In this case, please email{' '}
            <a href="mailto:help@spend.money">help@spend.money</a> so we can help you get unstuck.
          </Tip>
        </Modal.Body>
      </Modal>

      <Modal show={showExpensesWarning} onHide={() => setShowExpensesWarning(false)}>
        <Modal.Header closeButton>
          <Modal.Title>No recurring expenses?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          It’s extremely uncommon to have no recurring expenses. Please review the instructions and see if you can identify a few.
          Otherwise, your budget will be based solely on your income, which may not be accurate.
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'space-between' }}>
          <a href="/onboarding" onClick={(event) => handleNextStep(event, true)}>
            Continue anyway
          </a>
          <Button variant="primary" onClick={() => setShowExpensesWarning(false)}>
            Review recurring expenses
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRecurringIncomesWarning} onHide={() => setShowRecurringIncomesWarning(false)}>
        <Modal.Header closeButton>
          <Modal.Title>No recurring income?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          It’s extremely uncommon to have no recurring income. Even if you don’t receive a regular paycheck, consider marking your most
          consistent income as recurring. Without recurring income, Spend will set your spendable allowance to $0.00 and every expense will
          put you over budget.
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'space-between' }}>
          <a href="/onboarding" onClick={(event) => handleNextStep(event, true)}>
            Continue anyway
          </a>
          <Button variant="primary" onClick={() => setShowRecurringIncomesWarning(false)}>
            Review recurring income
          </Button>
        </Modal.Footer>
      </Modal>
    </Stack>
  );
}
