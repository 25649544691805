import React from 'react';
import RecurringExpensesTip from '../RecurringExpensesTip';
import RecurringExpensesByCategory from '../RecurringExpensesByCategory';
import BigSpinner from '../BigSpinner';
import AllowanceEquationWithIcons from './AllowanceEquationWithIcons';

function ExpensesStep({ accountsById, endDate, expenses, startDate }) {
  if (expenses.length === 0) return <BigSpinner label="Loading expenses for the last 30 days" />;

  return (
    <>
      <section>
        <AllowanceEquationWithIcons phase={1} />
      </section>

      <section>
        <p>
          <strong>
            Identify your recurring expenses from {startDate.format('MMMM D')} to {endDate.format('MMMM D')}.
          </strong>{' '}
          Your recurring expenses are subtracted from your recurring income to determine your allowance.
        </p>

        <RecurringExpensesTip />
      </section>

      <RecurringExpensesByCategory accountsById={accountsById} expenses={expenses} marginBottom={0} toggleRecurringOnly />
    </>
  );
}

export default ExpensesStep;
