import React from 'react';
import BetterAccordionItem from '../BetterAccordionItem';
import ListSection, { ListSectionItem } from './ListSection';
import { sortBy } from 'underscore';
import { useDashboard } from '../../contexts/DashboardContext';
import DollarAmount from '../DollarAmount';
import { Timescale } from '../../shared/BudgetCalendar';
import usePlans from '../../hooks/usePlans';
import { useInstallments } from '../../hooks/useInstallments';
import TransactionDate from '../TransactionDate';
import { Link } from 'react-router-dom';
import { generateRoute, Route } from '../../Routes';

export default function InstallmentsAccordionItem() {
  const {
    budgetPeriods,
    budget: { weekAmortizationsSum, weekGoalsSum, monthAmortizationsSum, monthGoalsSum },
    timescale,
  } = useDashboard();

  const { plansById, isLoading: isLoadingPlans, error: plansError } = usePlans();

  const {
    installments,
    isLoading: isLoadingInstallments,
    error: installmentsError,
  } = useInstallments({
    start: budgetPeriods.utilization.start.toDate(),
    end: budgetPeriods.utilization.end.toDate(),
  });

  let total = 0;

  if (timescale === Timescale.Week) total = weekGoalsSum + weekAmortizationsSum;
  else if (timescale === Timescale.Month) total = monthGoalsSum + monthAmortizationsSum;

  const isLoading = isLoadingPlans || isLoadingInstallments;

  return (
    <BetterAccordionItem
      eventKey="installments"
      header="Plans"
      isLoading={isLoading}
      secondLine="Installments made toward recovered transactions or goals"
      secondColumn={<DollarAmount amount={total} color />}
      error={plansError || installmentsError}
    >
      {installments.length > 0 && (
        <ListSection isLoading={isLoading}>
          {sortBy(installments, 'amount')
            .reverse()
            .map((installment) => (
              <ListSectionItem
                key={installment.transactionId}
                name={
                  <Link
                    to={generateRoute(plansById[installment.planId].transactionId ? Route.Recovery : Route.Goal, {
                      planId: installment.planId,
                    })}
                    style={{ color: 'inherit' }}
                  >
                    {plansById[installment.planId].name}
                  </Link>
                }
                value={<DollarAmount amount={installment.amount} per={timescale} color />}
                detail={<TransactionDate date={installment.date} />}
              />
            ))}
        </ListSection>
      )}
    </BetterAccordionItem>
  );
}
