import React from 'react';
import { Timescale } from '../../shared/BudgetCalendar';
import BetterAccordionItem from '../BetterAccordionItem';
import { useDashboard } from '../../contexts/DashboardContext';
import DollarAmount from '../DollarAmount';
import useBudget, { UseBudgetOptions } from '../../hooks/useBudget';

export default function CarryoverAccordionItem() {
  const {
    budget: { previousWeekRemainder, previousMonthRemainder },
    budgetPeriods: { utilization: utilizationPeriodStart },
    timescale,
  } = useDashboard();

  const budgetOptions: UseBudgetOptions = {};
  if (timescale === Timescale.Week) budgetOptions.weekEnd = utilizationPeriodStart.endOfPrevious();
  else if (timescale === Timescale.Month) budgetOptions.monthEnd = utilizationPeriodStart.endOfPrevious();
  const { budget: previousPeriodBudget, isLoading } = useBudget(budgetOptions);

  if (!previousPeriodBudget && !isLoading) return null;

  let carryover = 0;

  if (timescale === Timescale.Week) carryover = previousWeekRemainder;
  else if (timescale === Timescale.Month) carryover = previousMonthRemainder;

  return (
    <BetterAccordionItem
      eventKey="carryover"
      header="Carryover"
      secondLine={`${carryover > 0 ? 'Over' : 'Under'}spending from the previous ${timescale}`}
      secondColumn={<DollarAmount amount={carryover} color />}
      isLoading={isLoading}
    >
      <p className="mb-0">
        Last {timescale} you {carryover > 0 ? 'overspent' : 'underspent'} by <DollarAmount amount={carryover} absolute />, which is{' '}
        {carryover > 0 ? 'subtracted from' : 'added to'} your Safe to Spend this week.
      </p>
    </BetterAccordionItem>
  );
}
