import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useScopeStyles from '../hooks/useScopeStyles';
import useLandingPageVersion from '../hooks/useLandingPageVersion';
import { Route } from '../Routes';

function MarComCapacitorLayout() {
  useDocumentTitle();
  useScopeStyles('marcom-capacitor');
  useLandingPageVersion();

  return (
    <>
      <header>
        <div className="wrapper d-flex align-items-center justify-content-center">
          <Link to={Route.Root}>
            <img src="/logo.svg" width="100" height="24" alt="Spend logo, which is money in an envelope" className="logo" />
          </Link>
        </div>
      </header>

      <main>
        <Outlet />
      </main>
    </>
  );
}

export default MarComCapacitorLayout;
