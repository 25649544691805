import React, { useCallback, useEffect } from 'react';
import { PlaidLinkOnEvent, PlaidLinkOnLoad, PlaidLinkOnSuccess, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import BetterButton, { BetterButtonProps } from './BetterButton';
import { Metric, trackMetric } from '../utils/metrics';
import dayjs from 'dayjs';

interface PlaidLinkButtonProps extends BetterButtonProps {
  linkToken: string;
  onSuccess: PlaidLinkOnSuccess;
  children: React.ReactNode;
}

export default function PlaidLinkButton({ linkToken, onSuccess, children, ...betterButtonProps }: PlaidLinkButtonProps) {
  const onSuccessWithTracking = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    trackMetric(Metric.PLAID_LINK_SUCCESS);
    onSuccess(publicToken, metadata);
  }, []);

  const onLoad = useCallback<PlaidLinkOnLoad>(() => {
    trackMetric(Metric.PLAID_LINK_LOAD);
  }, []);

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // eventName should look like CLOSE_OAUTH or ERROR (https://plaid.com/docs/link/web/#link-web-onevent-eventName)
    // timestamp is emitted by all events (https://plaid.com/docs/link/web/#link-web-onevent-metadata-timestamp)
    trackMetric(`${Metric.PLAID_LINK_EVENT}_${eventName.toLowerCase()}`, metadata, { timestamp: dayjs(metadata.timestamp).toDate() });
  }, []);

  const plaidLinkOptions: PlaidLinkOptions = {
    token: linkToken,
    onSuccess: onSuccessWithTracking,
    onEvent,
    onLoad,
  };

  const { open, ready } = usePlaidLink(plaidLinkOptions);

  const handleClick = useCallback(() => {
    open();
  }, [open]);

  useEffect(() => {
    trackMetric(Metric.PLAID_LINK_BUTTON_RENDERED);
  }, []);

  useEffect(() => {
    ready && trackMetric(Metric.PLAID_LINK_BUTTON_READY);
  }, [ready]);

  return (
    <BetterButton
      isLoading={!linkToken}
      disabled={!ready}
      onClick={handleClick}
      loadingText="Securing link"
      metric={Metric.PLAID_LINK_BUTTON_CLICKED}
      {...betterButtonProps}
    >
      {children}
    </BetterButton>
  );
}
