import React from 'react';
import { Route } from '../Routes';
import { Link } from 'react-router-dom';
import BetterButton from './BetterButton';

interface MarComHeaderProps {
  handleSignIn: () => void;
  handleSignUp: () => void;
}

export default function MarComHeader({ handleSignIn, handleSignUp }: MarComHeaderProps) {
  return (
    <header>
      <div className="wrapper d-flex align-items-center">
        <div>
          <Link to={Route.Root}>
            <img src="/logo.svg" width="100" height="24" alt="Spend logo, which is money in an envelope" className="logo" />
          </Link>
        </div>
        <div className="ms-auto">
          <div style={{ display: 'flex', justifyContent: 'end', gap: 4 }}>
            <BetterButton variant="link" href="/blog">
              Blog
            </BetterButton>
            <BetterButton variant="link" onClick={handleSignIn}>
              Log in
            </BetterButton>
            <BetterButton onClick={handleSignUp}>Sign up</BetterButton>
          </div>
        </div>
      </div>
    </header>
  );
}
