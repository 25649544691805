import React from 'react';
import { Stack } from 'react-bootstrap';
import { BsEnvelope } from 'react-icons/bs';
import List from '../components/List';
import Tip from '../components/Tip';
import { HashLink as Link } from 'react-router-hash-link';
import BetterButton from '../components/BetterButton';
import { RECURRING_EXPENSES_EXAMPLES } from '../shared/constants';
import { Metric, useTrackMetric } from '../utils/metrics';

const HelpSectionId = Object.freeze({
  Allowance: 'allowance',
  AppleLogin: 'apple-login',
  Carryover: 'carryover',
  ConnectBankAccounts: 'connecting-your-bank-accounts',
  Goals: 'goals',
  GoogleLogin: 'google-login',
  HowBudgetCalculated: 'how-your-budget-is-calculated',
  IgnoredTransactions: 'ignoring-a-transaction',
  Installments: 'installments',
  Plans: 'plans',
  Recoveries: 'recoveries',
  RecurringExpenses: 'recurring-expenses',
  RecurringIncomes: 'recurring-income',
  Runway: 'runway',
  SafeToSpend: 'safe-to-spend',
});

function RecurringExpensesExplanation({ onLinkClick = () => {} }) {
  return (
    <>
      <div>
        There are certain expenses you expect to see <strong>every month</strong>. For most people, the main one is their rent or mortgage.
        But it could also be moving money into a savings account. Your balance{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.Allowance}`}>
          allowance
        </Link>{' '}
        is built in part by subtracting your recurring expenses from your{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.RecurringIncomes}`}>
          recurring income
        </Link>
        .
      </div>

      <div>
        <strong>Examples of recurring expenses:</strong>
      </div>

      <List>
        {RECURRING_EXPENSES_EXAMPLES.map(({ name, summary }) => (
          <List.Item key={name} icon="✅">
            {name}
            {summary && <> ({summary})</>}
          </List.Item>
        ))}
      </List>

      <div>
        Some expenses that are <strong>not</strong> recurring:
      </div>

      <List>
        {[
          'Groceries or dining out',
          'Gas',
          'Discretionary spending',
          'Travel',
          'Credit card payments and most transfers',
          'Online shopping',
        ].map((example) => (
          <List.Item key={example} icon="❌">
            {example}
          </List.Item>
        ))}
      </List>

      <div>
        Note that marking an expense as recurring for the current week won’t affect your allowance until the following week because your
        allowance is based on the previous month.
      </div>

      <div>
        Right now, Spend only supports monthly recurring expenses. Annual credit card membership fees or property taxes do need to be
        factored into your weekly budget so you don’t come up short when those bills are due. You can use{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.Recoveries}`}>
          recoveries
        </Link>{' '}
        and{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.Allowance}`}>
          goals
        </Link>{' '}
        to handle those types of expenses.
      </div>
    </>
  );
}

function RecurringIncomesExplanation({ onLinkClick = () => {} }) {
  return (
    <>
      <div>
        Recurring income represents income you can count on month-to-month. Recurring income for the current week is not included in your{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.Allowance}`}>
          allowance
        </Link>{' '}
        until the following week, when your budget is recalculated based on the previous month.
      </div>

      <div>
        Other deposits—like if someone Venmos you for their portion of dinner, when you picked up whole tab—should not be considered
        recurring income. These deposits simply change your{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.SafeToSpend}`}>
          Safe to Spend
        </Link>
        .
      </div>
    </>
  );
}

function IgnoredTransactionsExplanation({ onLinkClick = () => {} }) {
  return (
    <>
      <div>
        Ignoring a transaction, excludes it from your{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.SafeToSpend}`}>
          Safe to Spend
        </Link>
        . You can think of ignoring an expense as ”spending from your savings.” Ignoring an expense means that it won’t count against your{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.Allowance}`}>
          allowance
        </Link>
        . But it probably will still affect your available cash, which Spend will report on soon.
      </div>

      <div>
        Income can also be ignored. Think of it like “adding to your savings.” For example, if you sold some extra clothes online, consider
        ignoring these deposits so they don’t impact your Safe to Spend.
      </div>

      <div>
        Spend also automatically ignores certain transactions, like credit card payments, which usually appear as a debit/credit pair. If
        both sides don’t post in the same week, your budget will be thrown off for two weeks. Spend automatically ignores transactions that
        look like credit card payments to make staying on budget that much easier. If Spend gets it wrong, simply un-ignore that
        transaction.
      </div>

      <Tip>
        We automatically ignore credit card balance payments because Spend has already accounted for credit charges as part of your Safe to
        Spend. As long as you don’t have debt when you join Spend and don’t overspend, your credit card balance can be paid off in full each
        month.{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.Goals}`}>
          Goals
        </Link>{' '}
        may be used to pay down pre-existing debt.
      </Tip>
    </>
  );
}

function SafeToSpendExplanation() {
  return (
    <>
      <div>
        Safe to Spend is the most important number to track to stay on budget. It’s calculated each week (or month, if you prefer) by
        subtracting your spending from your allowance. Transactions that have been marked ignored, recurring, or recovered are not
        considered part of your spending.
      </div>
      <div>
        As long as you spend less than your Safe to Spend, you will be able to cover all of your recurring expenses and any new credit card
        charges.
      </div>
      <div>
        If you spend less than your Safe to Spend, the remainder will <Link to={`/help#${HelpSectionId.Carryover}`}>carry over</Link> to the
        next week. If you overspend, you’ll have less money the next week.
      </div>
      <div>
        <Tip>It’s important to connect all of your spending accounts to Spend. If you don’t, your Safe to Spend will not be accurate.</Tip>
      </div>
    </>
  );
}

function RecoveryExplanation({ onLinkClick = () => {} }) {
  return (
    <>
      <div>
        Recovering a transaction spreads out the amount over time. Life happens and often unexpected purchases must be made that will throw
        you off budget.
      </div>

      <div>
        Instead of{' '}
        <Link onClick={onLinkClick} to={`/help#${HelpSectionId.Carryover}`}>
          ignoring
        </Link>{' '}
        one-off transactions like a plane ticket, car repair, or impulse purchase, which leave you with a hole in your wallet, Recovering
        allows you to use a portion of your future allowance so you can, essentially, pay yourself back and return to where you started.
      </div>

      <div>
        Pending transactions are marked by your bank as needing more time to settle. For example, a restaurant may charge you for the bill,
        which would show up as a pending transaction, and would eventually increase to include the tip, potentially days later. Recovery
        plans based on a pending transaction will start off based on the value of the pending transaction and will update after settling.
      </div>
    </>
  );
}

export default function HelpPage() {
  useTrackMetric(Metric.HELP_PAGE_VISITED);

  return (
    <div className="wrapper">
      <section className="floating-card">
        <Stack gap={4}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <h1>Help Center</h1>
            </div>

            <div>
              <BetterButton size="sm" beforeIcon={<BsEnvelope />} href="mailto:help@spend.money">
                Email support
              </BetterButton>
            </div>
          </div>

          <Stack gap={2}>
            <h3 id={HelpSectionId.SafeToSpend}>Safe to Spend</h3>

            <SafeToSpendExplanation />
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.Allowance}>Allowance</h3>

            <div>
              Your allowance is the amount of money you can spend for a given week or month, while still affording all of your planned
              expenses. To calculate it, Spend subtracts your{' '}
              <Link to={`/help#${HelpSectionId.RecurringExpenses}`}>recurring expenses</Link> from your{' '}
              <Link to={`/help#${HelpSectionId.RecurringIncomes}`}>recurring income</Link>. Then, Spend subtracts any{' '}
              <Link to={`#${HelpSectionId.Installments}`}>installments</Link> made towards{' '}
              <Link to={`#${HelpSectionId.Recoveries}`}>recoveries</Link> or <Link to={`#${HelpSectionId.Goals}`}>goals</Link>. Finally, any
              underspend is added, or any overspend is subtracted.
            </div>

            <Tip>
              Your <Link to={`#${HelpSectionId.SafeToSpend}`}>Safe to Spend</Link> starts as your allowance and changes as you spend.
            </Tip>
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.RecurringExpenses}>Recurring expenses</h3>
            <RecurringExpensesExplanation />
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.RecurringIncomes}>Recurring income</h3>
            <RecurringIncomesExplanation />
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.IgnoredTransactions}>Ignoring a transaction</h3>
            <IgnoredTransactionsExplanation />
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.Carryover}>Carryover</h3>

            <div>
              At the end of each week, if there’s money left in your <Link to={`/help#${HelpSectionId.SafeToSpend}`}>Safe to Spend</Link>,
              your <Link to={`/help#${HelpSectionId.Allowance}`}>allowance</Link> the following week will be increased by that amount.
              Similarly, if you overspend, your allowance will be decreased by that amount.
            </div>
            <div>
              If you overspend consistently, it gets more and more difficult to afford your spending each week, because your Allowance is
              reduced.
            </div>
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.Plans}>Plans</h3>

            <div>
              Spend supports two kinds of plans, <Link to={`/help#${HelpSectionId.Recoveries}`}>recoveries</Link> and{' '}
              <Link to={`/help#${HelpSectionId.Goals}`}>goals</Link>. Both are ways to set aside part of your allowance to plan for an
              expense.
            </div>

            <div>
              Recovery is a way to set aside money to recover from an unexpected expense. The transaction will be ignored for the current
              week, and Spend will set aside a part of your allowance every week until the amount is recovered.
            </div>

            <div>
              Creating a goal sets aside money every week until the goal is reached. At any time, you can close & cash in the goal and the
              money will be added to your <Link to={`/help#${HelpSectionId.SafeToSpend}`}>Safe to Spend</Link>.
            </div>
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.Installments}>Installments</h3>

            <div>
              Installments are weekly contributions towards a <Link to={`/help#${HelpSectionId.Recoveries}`}>recoveries</Link> or{' '}
              <Link to={`/help#${HelpSectionId.Goals}`}>goals</Link>.
            </div>
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.Recoveries}>Recoveries</h3>
            <RecoveryExplanation />
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.Goals}>Goals</h3>

            <div>
              Goals are a way to set aside money for future expenses. For example, if you know you have a $500 car insurance bill due in 6
              months, you can set aside $19.23 each week to cover the bill when it comes due.
            </div>

            <div>
              At any time, you can close & cash in the goal and the money you’ve saved will be added to your{' '}
              <Link to={`#${HelpSectionId.SafeToSpend}`}>Safe to Spend</Link>.
            </div>
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.Runway}>Runway</h3>

            <div>
              Runway shows the number of months that your current cash balance would last, assuming that your spending stays consistent.
            </div>

            <div>
              First, Spend adds the total of all cash balances in your included spending accounts. Your recurring monthly expenses are
              already tracked as part of your <Link to={`/help#${HelpSectionId.Allowance}`}>allowance</Link>. Then, Spend calculates your
              average monthly spending. The sum of your recurring expenses is added to your average monthly spending to get your total
              monthly spending. Finally, your total cash balance is divided by your total monthly spending to get the number of months your
              cash will last.
            </div>

            <div>
              For example, if you have $2,000 in your checking account and $1,000 in your savings account, and your total monthly spending
              is $1,000, your runway would be 3 months ($3,000 / $1,000 per month = 3 months).
            </div>

            <div>
              Spend also tracks credit card debt and loan balances. If you have any of these liabilities, Spend also provides a runway that
              subtracts your liabilities from your cash balance before calculating the number of months your cash will last.
            </div>
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.GoogleLogin}>Google Login</h3>

            <div>
              If you’re having difficulty connecting to Google, please go to{' '}
              <Link target="_blank" to="https://accounts.google.com">
                accounts.google.com
              </Link>{' '}
              and ensure you can log in. If you’re still having issues logging in, please contact us at{' '}
              <Link to="mailto:help@spend.money">help@spend.money</Link>.
            </div>
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.AppleLogin}>Apple Login</h3>

            <div>
              If you’re having difficulty connecting to Apple, please go to{' '}
              <Link target="_blank" to="https://appleid.apple.com/">
                appleid.apple.com
              </Link>{' '}
              and ensure you can log in. If you’re still having issues logging in, please contact us at{' '}
              <Link to="mailto:help@spend.money">help@spend.money</Link>.
            </div>
          </Stack>

          <Stack gap={2}>
            <h3 id={HelpSectionId.ConnectBankAccounts}>Connecting your bank accounts</h3>

            <div>
              If you’re having difficulty connecting to your bank account, please make sure you’ve got your login and password for the bank
              correct. To make sure, go to your bank’s website and try to log in with your credentials there.
            </div>

            <div>
              For example, if you bank with Chase, go to{' '}
              <Link target="_blank" to="https://www.chase.com">
                chase.com
              </Link>{' '}
              and ensure that your login credentials work there. If they do, then they should also work on Spend.
            </div>

            <div>
              If you can log into your bank but can’t connect your account to Spend, please contact us at{' '}
              <Link to="mailto:help@spend.money">help@spend.money</Link>.
            </div>
          </Stack>
        </Stack>
      </section>
    </div>
  );
}

export {
  RecoveryExplanation,
  IgnoredTransactionsExplanation,
  RecurringExpensesExplanation,
  RecurringIncomesExplanation,
  SafeToSpendExplanation,
  HelpSectionId,
};
