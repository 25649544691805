import React, { createContext, useEffect } from 'react';
import IncomeStep from './IncomeStep';
import RecurringExpensesStep from './RecurringExpensesStep';
import SafeToSpendStep from './SafeToSpendStep';
import { Timescale } from '../../shared/BudgetCalendar';
import useLocalStorageState from '../../hooks/useLocalStorageState';
import SignUpStep from './SignUpStep';
import Breadcrumbs from './Breadcrumbs';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { generateRoute, Route } from '../../Routes';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { CloseButton } from 'react-bootstrap';
import IntroStep from './IntroStep';
import { RECURRING_EXPENSES_EXAMPLES } from '../../shared/constants';
import { Metric, trackMetric } from '../../utils/metrics';

interface Step {
  path: string;
  pageTitle: string;
  metricSuffix: string;
}

const STEPS: Step[] = [
  { pageTitle: 'Intro', path: 'intro', metricSuffix: 'intro' },
  { pageTitle: 'Income', path: 'income', metricSuffix: 'income' },
  { pageTitle: 'Recurring Expenses', path: 'recurring-expenses', metricSuffix: 'recurring_expenses' },
  { pageTitle: 'Safe to Spend', path: 'safe-to-spend', metricSuffix: 'safe_to_spend' },
  { pageTitle: 'Sign Up', path: 'sign-up', metricSuffix: 'sign_up' },
];

interface WelcomeContextType {
  allowanceMonthly: number;
  allowanceWeekly: number;
  discretionaryExpenses: Transaction[];
  discretionaryExpensesSum: number;
  monthlyIncome: number;
  recurringExpenses: string[];
  recurringExpensesSum: number;
  safeToSpend: number;
  setDiscretionaryExpenses: (transactions: Transaction[]) => void;
  setMonthlyIncome: (monthlyIncome: number) => void;
  setRecurringExpenses: (names: string[]) => void;
  setTimescale: (timescale: Timescale) => void;
  stepIndex: number;
  timescale: Timescale;
}

const WelcomeContext = createContext<WelcomeContextType>(null);

interface Transaction {
  name: string;
  amount: number;
  icon: string;
}

export default function Welcome() {
  const { step: stepPath } = useParams();

  const stepIndex = STEPS.findIndex((step) => step.path === stepPath);
  const step = STEPS[stepIndex];

  const navigate = useNavigate();

  const [monthlyIncome, setMonthlyIncome] = useLocalStorageState('welcome/monthlyIncome', { defaultValue: 0 });
  const [recurringExpenses, setRecurringExpenses] = useLocalStorageState<string[]>('welcome/recurringExpenses', { defaultValue: [] });
  const [discretionaryExpenses, setDiscretionaryExpenses] = useLocalStorageState<Transaction[]>('welcome/discretionaryExpenses', {
    defaultValue: [],
  });
  const [timescale, setTimescale] = useLocalStorageState<Timescale>('welcome/timescale', { defaultValue: Timescale.Week });

  const recurringExpensesSum = recurringExpenses.reduce(
    (memo, expenseName) =>
      memo + RECURRING_EXPENSES_EXAMPLES.find((example) => example.name === expenseName).percentOfIncome * monthlyIncome * -1,
    0,
  );
  const allowanceMonthly = monthlyIncome + recurringExpensesSum;
  const allowanceWeekly = allowanceMonthly / 4;
  const discretionaryExpensesSum = discretionaryExpenses.reduce((memo, expense) => memo + expense.amount, 0);
  const safeToSpend = allowanceMonthly + discretionaryExpensesSum;

  useDocumentTitle(STEPS[stepIndex].pageTitle, 'Welcome');

  useEffect(() => step && trackMetric(`${Metric.WELCOME_STEP_REACHED}_${step.metricSuffix}`), [step]);

  // Redirect to the first step if the step provided is invalid
  if (stepIndex === -1) return <Navigate to={generateRoute(Route.Welcome, { step: STEPS[0].path })} replace />;

  function handleReturnToHomepage() {
    navigate(Route.Root);
  }

  return (
    <WelcomeContext.Provider
      value={{
        allowanceMonthly,
        allowanceWeekly,
        discretionaryExpenses,
        discretionaryExpensesSum,
        monthlyIncome,
        recurringExpenses,
        recurringExpensesSum,
        safeToSpend,
        setDiscretionaryExpenses,
        setMonthlyIncome,
        setRecurringExpenses,
        setTimescale,
        stepIndex,
        timescale,
      }}
    >
      <header className="mb-4 d-flex justify-content-between align-items-center">
        <div style={{ flex: 1 }}>
          <img src="/logo.svg" width="130" alt="Spend logo, which is money in an envelope" className="logo" style={{ display: 'block' }} />
        </div>

        <div>
          <CloseButton onClick={handleReturnToHomepage} aria-label="Close" />
        </div>
      </header>
      <Breadcrumbs />
      {stepIndex === 0 && <IntroStep />}
      {stepIndex === 1 && <IncomeStep />}
      {stepIndex === 2 && <RecurringExpensesStep />}
      {stepIndex === 3 && <SafeToSpendStep />}
      {stepIndex === 4 && <SignUpStep />}
    </WelcomeContext.Provider>
  );
}

export { Transaction, WelcomeContext, STEPS };
