import React, { ForwardedRef, MouseEventHandler, useCallback } from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import { forwardRef } from 'react';
import { trackMetric } from '../utils/metrics';
import { ButtonVariant } from 'react-bootstrap/esm/types';

interface BetterButtonProps extends ButtonProps {
  afterIcon?: React.ReactNode;
  beforeIcon?: React.ReactNode;
  children?: React.ReactNode;
  hideLoadingIndicators?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  onClick?: MouseEventHandler;
  metric?: string;
  props?: React.ComponentProps<typeof Button>;
  size?: 'sm' | 'lg';
  style?: React.CSSProperties;
  variant?: ButtonVariant;
  wrap?: boolean;
}

const BetterButton = forwardRef(
  (
    {
      isLoading,
      hideLoadingIndicators,
      loadingText,
      disabled,
      beforeIcon: BeforeIcon,
      afterIcon: AfterIcon,
      onClick,
      children,
      wrap = false,
      metric,
      ...props
    }: BetterButtonProps,
    ref: ForwardedRef<any>,
  ) => {
    const content = isLoading && loadingText && !hideLoadingIndicators ? `${loadingText}…` : children;

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (metric) trackMetric(metric);
        if (onClick) onClick(event);
      },
      [metric, onClick],
    );

    return (
      <Button disabled={isLoading || disabled} onClick={isLoading || disabled ? null : handleClick} ref={ref} {...props}>
        <div className="d-flex align-items-center" style={{ columnGap: 6 }}>
          {isLoading && !hideLoadingIndicators ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : BeforeIcon}
          {wrap ? content : <span className="text-nowrap">{content}</span>}
          {AfterIcon}
        </div>
      </Button>
    );
  },
);

export default BetterButton;
export { BetterButtonProps };
