const FIRESTORE_MAX_BATCH_SIZE = 500;
/** Even though Firestore’s docs say the match batch size is 500, 100 seems to *actually* work. */
const FIRESTORE_MAX_BATCH_SIZE_FOR_DELETE = 100;

/**
 * A mapping of Bootstrap color variables to hex values.
 * Note: this map assumes light mode. Do not use this map
 * for dark mode implementations.
 */
const BOOTSTRAP_COLORS = {
  'danger-bg-subtle': '#f8d7da',
  'danger-border-subtle': '#f1aeb5',
  'gray-500': '#adb5bd',
  'secondary-bg-subtle': '#e2e3e5',
  'success-bg-subtle': '#d1e7dd',
  'success-border-subtle': '#a3cfbb',
  'warning-bg-subtle': '#fff3cd',
  'warning-border-subtle': '#ffe69c',
  danger: '#dc3545',
  dark: '#212529',
  light: '#f8f9fa',
  success: '#198754',
  warning: '#ffc107',
};

const WEEK_RUNWAY_PREVIOUS_PERIODS = 4;
const MONTH_RUNWAY_PREVIOUS_PERIODS = 3;

interface RecurringExpenseExample {
  name: string;
  percentOfIncome: number;
  summary?: string;
  emoji: string;
}

const RECURRING_EXPENSES_EXAMPLES: RecurringExpenseExample[] = [
  { name: 'Rent or mortgage', percentOfIncome: 0.4, emoji: '🏠' },
  { name: 'Car payment(s)', percentOfIncome: 0.055, emoji: '🚙' },
  { name: 'Utilities', percentOfIncome: 0.02, summary: 'power, water, recycling, etc.', emoji: '🚰' },
  { name: 'Health insurance', percentOfIncome: 0.04, emoji: '🩺' },
  { name: 'Property insurance', percentOfIncome: 0.02, emoji: '🛡️' },
  { name: 'Child care', percentOfIncome: 0.045, emoji: '👶' },
  { name: 'Cell phone', percentOfIncome: 0.01, emoji: '📱' },
  { name: 'Internet', percentOfIncome: 0.01, emoji: '🛜' },
  { name: 'Gym membership(s)', percentOfIncome: 0.018, emoji: '🏋️' },
  { name: 'Streaming service(s)', summary: 'Hulu, Netflix, Spotify, Disney+, etc.', percentOfIncome: 0.008, emoji: '📺' },
  { name: 'Recurring health care', summary: 'medication, therapy, chiropractor, etc.', percentOfIncome: 0.02, emoji: '🏥' },
  { name: 'Gifts & charity', percentOfIncome: 0.02, emoji: '🎁' },
  { name: 'Savings or investments', summary: 'contributing to a rainy day fund or stock plan', percentOfIncome: 0.02, emoji: '📈' },
];

const SPEND_DEMO_ACCOUNT_NAME = 'Spend Demo Checking Account';
const SPEND_DEMO_INSTITUTION_NAME = 'Spend Demo Bank';

export {
  BOOTSTRAP_COLORS,
  FIRESTORE_MAX_BATCH_SIZE,
  FIRESTORE_MAX_BATCH_SIZE_FOR_DELETE,
  WEEK_RUNWAY_PREVIOUS_PERIODS,
  MONTH_RUNWAY_PREVIOUS_PERIODS,
  RECURRING_EXPENSES_EXAMPLES,
  SPEND_DEMO_ACCOUNT_NAME,
  SPEND_DEMO_INSTITUTION_NAME,
};
