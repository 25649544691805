import React, { useState } from 'react';
import { ComponentsSeparatedByDots } from './DotSeparator';
import TransactionDate from './TransactionDate';
import TransactionPending from './TransactionPending';
import TransactionAmountButton from './TransactionAmountButton';
import { Form, Stack } from 'react-bootstrap';
import toggleTransactionField from '../utils/toggleTransactionField';
import { useAuth } from '../App';
import DollarAmount from './DollarAmount';
import { BsArrowRepeat, BsArrowReturnRight, BsClipboardData, BsCreditCard, BsPencilSquare } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { generateRoute, Route } from '../Routes';
import { AccountDocData, PlanDocData, TransactionDocData } from '../types';
import TransactionModalForm from './TransactionModalForm';
import TransactionName from './TransactionName';
import AccountMask from './AccountMask';
import usePlans from '../hooks/usePlans';
import Pie from './CircleProgressBar';

function generateDomId(transactionId: string, suffix?: string) {
  let domId = `transaction-${transactionId}`;
  return suffix ? `${domId}-${suffix}` : domId;
}

interface TransactionRowProps {
  account: AccountDocData;
  highlightNameWords?: string[];
  runningBalance: number | boolean;
  showCategoryStartingAt?: number;
  toggleRecurringOnly?: boolean;
  transaction: TransactionDocData;
  plan?: PlanDocData;
}

function TransactionRow({
  transaction,
  account,
  plan,
  runningBalance,
  highlightNameWords = [],
  showCategoryStartingAt = 0,
  toggleRecurringOnly = false,
}: TransactionRowProps) {
  const { uid } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const { amount, category, isGuessedCC, isRecurring, name, plaidRecurringStreamId, transactionId, planId, dateOriginal, nameOriginal } =
    transaction;

  return (
    <div id={generateDomId(transactionId)} className={`transaction list-item-with-border ${toggleRecurringOnly ? '' : 'clickable'}`}>
      {toggleRecurringOnly && (
        <div style={{ float: 'left' }}>
          <Form.Check
            id={generateDomId(transactionId, 'recurring-toggle')}
            type="switch"
            defaultChecked={isRecurring}
            onChange={() => toggleTransactionField(uid, transaction, 'isRecurring')}
          />
        </div>
      )}

      <div style={{ flex: 1 }}>
        <div style={{ float: 'right', marginLeft: 10 }}>
          {toggleRecurringOnly ? (
            <DollarAmount amount={amount} color showDecimals />
          ) : (
            <TransactionAmountButton transaction={transaction} />
          )}

          {typeof runningBalance === 'number' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: 2,
                gap: 4,
              }}
              className="small-font text-muted"
            >
              <BsArrowReturnRight />
              <DollarAmount amount={runningBalance} color />
            </div>
          )}
        </div>

        <div onClick={toggleRecurringOnly ? undefined : () => setShowModal(true)}>
          {toggleRecurringOnly ? (
            <label htmlFor={generateDomId(transactionId, 'recurring-toggle')} style={{ display: 'block', cursor: 'pointer' }}>
              <TransactionName id={transactionId} name={name} nameOriginal={nameOriginal} highlight={highlightNameWords} />
            </label>
          ) : (
            <div>
              <TransactionName id={transactionId} name={name} nameOriginal={nameOriginal} highlight={highlightNameWords} />
            </div>
          )}

          <div className="small-font text-muted">
            <div>
              <TransactionRowMetadata transaction={transaction} account={account} />
            </div>
            {category && (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                {category.slice(showCategoryStartingAt).join(' / ')}
                {isGuessedCC && <BsCreditCard title="Automatically ignored as credit card payment" />}
                {plaidRecurringStreamId && <BsArrowRepeat title="Automatically identified as recurring" />}
                {(dateOriginal || nameOriginal) && <BsPencilSquare title="This transaction has been edited" />}
              </div>
            )}
            {plan && (
              <Stack direction="horizontal" style={{ gap: 2 }}>
                <Pie percentage={(plan.installmentsSum / plan.targetAmount) * 100} color="var(--bs-success)" />
                <Link to={generateRoute(Route.Recovery, { planId })} style={{ color: 'inherit' }}>
                  Recovered <DollarAmount amount={plan.installmentsSum} absolute />
                </Link>
              </Stack>
            )}
          </div>
        </div>
      </div>

      {showModal && <TransactionModalForm onHide={() => setShowModal(false)} transaction={transaction} />}
    </div>
  );
}

type TransactionRowsProps = {
  accountsById: any;
  endingBalance?: number;
  highlightNameWords?: string[];
  showCategoryStartingAt?: number;
  toggleRecurringOnly?: boolean;
  transactions: TransactionDocData[];
  [key: string]: any;
};

function TransactionRows({
  transactions,
  accountsById,
  plansById,
  endingBalance,
  highlightNameWords = [],
  ...props
}: TransactionRowsProps) {
  // Keep track so we can build the running balance using the previous transaction's amount.
  let previousTransactionAmount = 0;
  let runningBalance = endingBalance;

  return (
    <div id="transactions" style={{ display: 'flex', flexDirection: 'column' }}>
      {transactions.map((transaction) => {
        const shouldCalculateRunningBalance = endingBalance && !(transaction.isIgnored || transaction.isRecurring || transaction.planId);

        if (shouldCalculateRunningBalance) {
          runningBalance -= previousTransactionAmount;
          previousTransactionAmount = transaction.amount;
        }

        return (
          <TransactionRow
            key={transaction.transactionId}
            transaction={transaction}
            account={accountsById[transaction.accountId]}
            plan={transaction.planId && plansById[transaction.planId]}
            runningBalance={shouldCalculateRunningBalance && runningBalance}
            highlightNameWords={highlightNameWords}
            {...props}
          />
        );
      })}
    </div>
  );
}

function TransactionRowMetadata({ transaction, account }: { transaction: TransactionDocData; account: AccountDocData }) {
  return (
    <ComponentsSeparatedByDots
      components={[
        transaction.pending && <TransactionPending />,
        <TransactionDate date={transaction.date} />,
        account && account.institution && <AccountMask account={account} />,
      ]}
    />
  );
}

export default TransactionRow;
export { TransactionRows, TransactionRowMetadata };
