import React, { useEffect } from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Onboarding from '../components/Onboarding';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../App';
import { Route } from '../Routes';

export default function OnboardingPage() {
  useDocumentTitle('Onboarding');

  const { isOnboarding } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOnboarding) navigate(Route.Root);
  }, [isOnboarding]);

  return <Onboarding />;
}
