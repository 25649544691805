import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Transactions from '../components/Transactions';
import { Metric, useTrackMetric } from '../utils/metrics';

export default function TransactionsPage() {
  useDocumentTitle('Ledger');
  useTrackMetric(Metric.TRANSACTIONS_PAGE_VISITED);

  return <Transactions />;
}
